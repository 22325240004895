export default (response, fileName) => {
  const URL = window.URL.createObjectURL(
    new Blob([response.data], {
      type: response.headers['content-type'],
    }),
  );
  const $ANCHOR_ELEM = document.createElement('a');
  $ANCHOR_ELEM.href = URL;
  $ANCHOR_ELEM.setAttribute('download', fileName);
  document.body.appendChild($ANCHOR_ELEM);
  $ANCHOR_ELEM.click();
  window.URL.revokeObjectURL(URL);
  document.body.removeChild($ANCHOR_ELEM);
};
