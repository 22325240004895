var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "general-function-popup",
    { staticClass: "call-history-popup", attrs: { isNoneBottomButton: true } },
    [
      _c(
        "div",
        { staticClass: "title", attrs: { slot: "title" }, slot: "title" },
        [
          _c(
            "button",
            { staticClass: "hidden" },
            [
              _c("vue-material-icon", {
                attrs: { name: "keyboard_arrow_left", size: 30 },
              }),
            ],
            1
          ),
          _c("h2", [_vm._v("영상 목록")]),
          _c(
            "button",
            { staticClass: "close-button", on: { click: _vm.onClosePopup } },
            [_c("vue-material-icon", { attrs: { name: "close", size: 24 } })],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "contents",
          attrs: { slot: "contents" },
          slot: "contents",
        },
        [
          _c("div", { staticClass: "call-history-info" }, [
            _c("span", { staticClass: "call-id" }, [
              _vm._v(" Call ID "),
              _c("b", [_vm._v(_vm._s(_vm.callId))]),
            ]),
            _vm.isFactoryComp
              ? _c("span", { staticClass: "download-count" }, [
                  _vm._v(" 남아있는 다운로드 수 "),
                  _c("b", [
                    _vm._v(_vm._s(_vm.downloadLimit - _vm.currentDownloadCnt)),
                  ]),
                ])
              : _vm._e(),
          ]),
          _vm.callHistoryContents.length > 0
            ? _c(
                "ul",
                _vm._l(_vm.callHistoryContents, function (content, index) {
                  return _c("li", { key: index }, [
                    _c("div", {
                      staticClass: "background-image",
                      style: {
                        backgroundImage: "url(" + content["tumb"] + ")",
                      },
                    }),
                    _c("ul", [
                      _c("li", [
                        _c("span", [_vm._v("업로더")]),
                        _c("span", { staticClass: "uploader-wrapper" }, [
                          _c("span", [
                            _vm._v(_vm._s(content["uploader"]["companyName"])),
                          ]),
                          _c("span", [
                            _vm._v(_vm._s(content["uploader"]["userName"])),
                          ]),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("재생 시간")]),
                        _c("span", [
                          _vm._v(
                            _vm._s(
                              _vm._f("formatSecondsToFullTime")(
                                content["playSeconds"]
                              )
                            )
                          ),
                        ]),
                      ]),
                      _c("li", [
                        _c("span", [_vm._v("태그")]),
                        content["tagList"].length > 0
                          ? _c(
                              "span",
                              { staticClass: "label-wrapper" },
                              _vm._l(content["tagList"], function (tag, index) {
                                return _c(
                                  "span",
                                  {
                                    key: "tag-" + index,
                                    staticClass: "gray-label smaller",
                                  },
                                  [_vm._v(" " + _vm._s(tag) + " ")]
                                )
                              }),
                              0
                            )
                          : _c("span", [_vm._v("-")]),
                      ]),
                    ]),
                    _vm.isFactoryComp
                      ? _c(
                          "div",
                          { staticClass: "button-wrapper" },
                          [
                            _c(
                              "button-basic",
                              {
                                attrs: { color: "red", size: "s", width: 100 },
                                on: {
                                  click: function ($event) {
                                    return _vm.onClickDeleteContent(content.id)
                                  },
                                },
                              },
                              [_vm._v(" 삭제 ")]
                            ),
                            _c(
                              "button-basic",
                              {
                                attrs: {
                                  color: "dark",
                                  size: "s",
                                  width: 100,
                                  "loading-dot":
                                    _vm.downloadingFileContentId === content.id,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.getDownloadCallHistoryContent(
                                      content
                                    )
                                  },
                                },
                              },
                              [_vm._v(" 다운로드 ")]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                }),
                0
              )
            : _c("p", { staticClass: "list-data-none" }, [
                _vm._v("영상 목록이 없습니다."),
              ]),
          _c("confirm-popup", {
            attrs: {
              isShow: _vm.showedPopupName === "ConfirmPopup",
              contentsKey: _vm.popupContentsKey,
            },
            on: { confirm: _vm.onConfirmInPopup, close: _vm.togglePopup },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }