<template>
  <section class="main-section bg-gray60">
    <main-section-header title="통화목록" subtitle="통화 목록과 업로드된 영상을 확인할 수 있습니다. " />
    <div class="container">
      <list-utility-menu
        :value="keyword"
        :hasSearchInput="true"
        placeholder="이름으로 검색하세요"
        @input="onChangeKeyword"
        @button="onMoveSearchedPage(1)"
        @keyupenter="onMoveSearchedPage(1)"
      >
        <calendar-picker slot="calendar" />
      </list-utility-menu>
      <list :list="callHistoryList" :header-list="callHistoryHeaderList">
        <li slot="list-body" v-for="(callHistory, index) in callHistoryList" :key="`history-${index}`">
          <span>{{ callHistory['id'] }}</span>
          <span>{{ callHistory['createDT'] | localDateYYYYMMDDTime }}</span>
          <span>{{ callHistory['durations'] | formatSecondsToFullTime }}</span>
          <span class="members">
            <span
              class="gray-label"
              :title="`${callHistory['master']['companyName']} ${callHistory['master']['userName']}`"
            >
              <b>{{ callHistory['master']['companyName'] }}</b>
              {{ callHistory['master']['userName'] }}
            </span>
          </span>
          <span class="members">
            <span
              v-for="(member, index) in callHistory['joinList']"
              :key="`members-${index}`"
              class="gray-label"
              :title="`${member['companyName']} ${member['userName']}`"
            >
              <template v-if="member['userName']">
                <b>{{ member['companyName'] }}</b>
                {{ member['userName'] }}
              </template>
              <template v-else>{{ $t('message.deleted_user') }}</template>
            </span>
          </span>
          <span>
            <button v-if="callHistory['hasVideo']" class="text-button" @click="onClickVideoList(callHistory.id)">
              영상 목록
            </button>
          </span>
        </li>
        <p slot="no-list-description">{{ noListDescription }}</p>
      </list>
      <pagination
        v-if="totalPageCount > 1"
        :page="page"
        :totalPageCount="totalPageCount"
        :pageSize="pageSize"
        @setPageNum="setPageNum"
      />
    </div>
    <call-history-popup
      v-if="showedPopupName === 'CallHistoryPopup'"
      :callId="selectedCallId"
      @close="onCloseCallHistoryPopup"
    />
  </section>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import MainSectionHeader from '@/component/mainSectionHeader/MainSectionHeader';
import ListUtilityMenu from '@/component/list/ListUtilityMenu';
import CalendarPicker from '@/component/calendar/CalendarPicker';
import List from '@/component/list/List';
import ListHeaderData from './ListHeaderData.json';
import Pagination from '@/component/pagination/Pagination';
import CallHistoryPopup from '@/component/popup/callHistoryPopup/CallHistoryPopup';
import defaultDateRange from '@/const/defaultDateRange';

export default {
  name: 'CallHistory',
  props: [],
  data() {
    return {
      showedPopupName: '',
      keyword: '',
      page: 1,
      totalPageCount: 0,
      pageSize: 20,
      callHistoryList: null,
      callHistoryHeaderList: ListHeaderData,
      selectedCallId: null,
      isRequestedDataWithKeyword: false,
    };
  },
  computed: {
    ...mapState('users', ['myInfo', 'currentCompany']),
    startDT() {
      return this.$route.query.startDT || this.$options.filters.localDateYYYYMMDD(defaultDateRange.start);
    },
    endDT() {
      return this.$route.query.endDT || this.$options.filters.localDateYYYYMMDD(defaultDateRange.end);
    },
    noListDescription() {
      if (this.isRequestedDataWithKeyword) {
        return '일치하는 검색 결과가 없습니다.';
      }
      return '해당 날짜에 등록된 통화목록이 없습니다.';
    },
  },
  created() {
    this.getCallHistories();
  },
  mounted() {},
  watch: {
    $route() {
      this.getCallHistories();
    },
  },
  methods: {
    ...mapActions('callHistories', ['GET_CALL_HISTORIES']),
    togglePopup(popupName) {
      this.showedPopupName = popupName || '';
    },
    onCloseCallHistoryPopup(callHistoryContents) {
      this.togglePopup();
      if (callHistoryContents.length === 0) {
        this.getCallHistories();
      }
    },
    onClickVideoList(callId) {
      this.selectedCallId = callId;
      this.togglePopup('CallHistoryPopup');
    },
    setPageNum(page) {
      this.page = page;
      this.onMoveSearchedPage();
    },
    onChangeKeyword(changeKeyword) {
      this.keyword = changeKeyword;
    },
    onMoveSearchedPage(page) {
      this.$router
        .push({
          query: {
            startDT: this.startDT || undefined,
            endDT: this.endDT || undefined,
            keyword: this.keyword || undefined,
            page: page || this.page || undefined,
          },
        })
        .catch((err) => err);
    },
    setQueryParams() {
      this.keyword = this.$route.query.keyword || '';
      this.page = this.$route.query.page || 1;
    },
    async getCallHistories() {
      try {
        this.isRequestedDataWithKeyword = !!this.keyword;
        this.totalPageCount = 0;
        this.callHistoryList = null;
        this.setQueryParams();
        const data = await this.GET_CALL_HISTORIES([
          this.keyword,
          this.startDT,
          this.endDT,
          this.page,
          this.pageSize,
          this.currentCompany,
        ]);
        this.callHistoryList = data.result;
        this.totalPageCount = data['metaData'].totalPageCount;
      } catch (error) {
        if (error.response.status === 404) {
          const path = window.location.hash.split('#/')[1];
          this.$router.replace(`/notfound?path=${path}`).catch((err) => err);
        }
      }
    },
  },
  components: { CallHistoryPopup, Pagination, List, CalendarPicker, ListUtilityMenu, MainSectionHeader },
};
</script>

<style scoped lang="scss">
@import 'CallHistory';
</style>
