var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "main-section bg-gray60" },
    [
      _c("main-section-header", {
        attrs: {
          title: "통화목록",
          subtitle: "통화 목록과 업로드된 영상을 확인할 수 있습니다. ",
        },
      }),
      _c(
        "div",
        { staticClass: "container" },
        [
          _c(
            "list-utility-menu",
            {
              attrs: {
                value: _vm.keyword,
                hasSearchInput: true,
                placeholder: "이름으로 검색하세요",
              },
              on: {
                input: _vm.onChangeKeyword,
                button: function ($event) {
                  return _vm.onMoveSearchedPage(1)
                },
                keyupenter: function ($event) {
                  return _vm.onMoveSearchedPage(1)
                },
              },
            },
            [
              _c("calendar-picker", {
                attrs: { slot: "calendar" },
                slot: "calendar",
              }),
            ],
            1
          ),
          _c(
            "list",
            {
              attrs: {
                list: _vm.callHistoryList,
                "header-list": _vm.callHistoryHeaderList,
              },
            },
            [
              _vm._l(_vm.callHistoryList, function (callHistory, index) {
                return _c(
                  "li",
                  {
                    key: "history-" + index,
                    attrs: { slot: "list-body" },
                    slot: "list-body",
                  },
                  [
                    _c("span", [_vm._v(_vm._s(callHistory["id"]))]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("localDateYYYYMMDDTime")(
                            callHistory["createDT"]
                          )
                        )
                      ),
                    ]),
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm._f("formatSecondsToFullTime")(
                            callHistory["durations"]
                          )
                        )
                      ),
                    ]),
                    _c("span", { staticClass: "members" }, [
                      _c(
                        "span",
                        {
                          staticClass: "gray-label",
                          attrs: {
                            title:
                              callHistory["master"]["companyName"] +
                              " " +
                              callHistory["master"]["userName"],
                          },
                        },
                        [
                          _c("b", [
                            _vm._v(
                              _vm._s(callHistory["master"]["companyName"])
                            ),
                          ]),
                          _vm._v(
                            " " +
                              _vm._s(callHistory["master"]["userName"]) +
                              " "
                          ),
                        ]
                      ),
                    ]),
                    _c(
                      "span",
                      { staticClass: "members" },
                      _vm._l(callHistory["joinList"], function (member, index) {
                        return _c(
                          "span",
                          {
                            key: "members-" + index,
                            staticClass: "gray-label",
                            attrs: {
                              title:
                                member["companyName"] +
                                " " +
                                member["userName"],
                            },
                          },
                          [
                            member["userName"]
                              ? [
                                  _c("b", [
                                    _vm._v(_vm._s(member["companyName"])),
                                  ]),
                                  _vm._v(
                                    " " + _vm._s(member["userName"]) + " "
                                  ),
                                ]
                              : [
                                  _vm._v(
                                    _vm._s(_vm.$t("message.deleted_user"))
                                  ),
                                ],
                          ],
                          2
                        )
                      }),
                      0
                    ),
                    _c("span", [
                      callHistory["hasVideo"]
                        ? _c(
                            "button",
                            {
                              staticClass: "text-button",
                              on: {
                                click: function ($event) {
                                  return _vm.onClickVideoList(callHistory.id)
                                },
                              },
                            },
                            [_vm._v(" 영상 목록 ")]
                          )
                        : _vm._e(),
                    ]),
                  ]
                )
              }),
              _c(
                "p",
                {
                  attrs: { slot: "no-list-description" },
                  slot: "no-list-description",
                },
                [_vm._v(_vm._s(_vm.noListDescription))]
              ),
            ],
            2
          ),
          _vm.totalPageCount > 1
            ? _c("pagination", {
                attrs: {
                  page: _vm.page,
                  totalPageCount: _vm.totalPageCount,
                  pageSize: _vm.pageSize,
                },
                on: { setPageNum: _vm.setPageNum },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.showedPopupName === "CallHistoryPopup"
        ? _c("call-history-popup", {
            attrs: { callId: _vm.selectedCallId },
            on: { close: _vm.onCloseCallHistoryPopup },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }