<template>
  <div class="calendar-wrapper">
    <ul>
      <li
        v-for="(pickDateStandard, index) in pickDateStandards"
        :key="index"
        :class="{ selected: changedPickDateStandTitle === pickDateStandard.title }"
        @click="onClickPickDateStands(pickDateStandard)"
      >
        {{ pickDateStandard.title }}
      </li>
    </ul>
    <div class="calendar">
      <div class="selected-date">
        <span class="date">
          <vue-material-icon name="event_available" :size="16" />
          <span>{{ changedDateRange.start | localDateYYYYMMDD }}</span>
        </span>
        <span>~</span>
        <span class="date">
          <vue-material-icon name="event_available" :size="16" />
          <span>{{ changedDateRange.end | localDateYYYYMMDD }}</span>
        </span>
      </div>
      <v-date-picker
        v-model="changedDateRange"
        color="blue"
        is-range
        :masks="{ title: 'YYYY년 MMMM' }"
        :max-date="new Date()"
        trim-weeks
        ref="calendar"
        @input="onClickDatePicker"
      />
      <div class="button-wrapper">
        <button @click="onCancel">취소</button>
        <button @click="onSave">적용</button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Calendar',
  props: ['isShow', 'selectedDateRange', 'selectedPickDateStand'],
  data() {
    return {
      changedDateRange: {},
      changedPickDateStand: '',
      pickDateStandards: [
        { title: '어제', start: -1, end: -1 },
        { title: '오늘', start: 0, end: 0 },
        { title: '지난 7일', start: -6, end: 0 },
        { title: '지난 15일', start: -14, end: 0 },
        { title: '지난 30일', start: -29, end: 0 },
      ],
    };
  },
  computed: {
    changedPickDateStandTitle() {
      if (this.getIsSameDate({ date1: this.changedDateRange.start, date2: this.changedDateRange.end })) {
        if (this.getIsSameDate({ date1: this.changedDateRange.start, dateSubNum: 1 })) {
          return '어제';
        } else if (this.getIsSameDate({ date1: this.changedDateRange.start, date2: new Date() })) {
          return '오늘';
        }
      } else if (this.getIsSameDate({ date1: this.changedDateRange.start, dateSubNum: 6 })) {
        return '지난 7일';
      } else if (this.getIsSameDate({ date1: this.changedDateRange.start, dateSubNum: 14 })) {
        return '지난 15일';
      } else if (this.getIsSameDate({ date1: this.changedDateRange.start, dateSubNum: 29 })) {
        return '지난 30일';
      }
      return '';
    },
  },
  created() {},
  mounted() {},
  watch: {
    isShow(newVal) {
      if (newVal) {
        this.changedDateRange = this.selectedDateRange;
        this.changedPickDateStand = this.selectedPickDateStand;
        this.moveCalendarToday();
      }
    },
  },
  methods: {
    getIsSameDate({ date1, date2, dateSubNum }) {
      if (dateSubNum) {
        date2 = new Date(new Date().setDate(new Date().getDate() - dateSubNum));
      }
      return this.$options.filters.localDateYYYYMMDD(date1) === this.$options.filters.localDateYYYYMMDD(date2);
    },
    async moveCalendarToday() {
      const calendar = this.$refs.calendar;
      await calendar.move(this.changedDateRange.end, { transition: 'none' });
    },
    onClickDatePicker() {
      this.changedPickDateStand = '';
    },
    onClickPickDateStands(pickDateStandard) {
      this.changedDateRange = {
        start: new Date(new Date().setDate(new Date().getDate() + pickDateStandard.start)),
        end: new Date(new Date().setDate(new Date().getDate() + pickDateStandard.end)),
      };

      setTimeout(() => {
        this.changedPickDateStand = pickDateStandard.title;
        this.moveCalendarToday();
      }, 0);
    },
    onCancel() {
      this.$emit('close');
    },
    onSave() {
      this.$emit('save', this.changedDateRange, this.changedPickDateStand);
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
@import 'Calendar';
</style>
