<template>
  <div class="calendar-picker">
    <button :class="{ focused: isShowCalendar }" @click="toggleShowCalendar">
      <vue-material-icon name="event_available" :size="14" />
      <span>{{ selectedDateRange.start | localDateYYYYMMDD }} ~ {{ selectedDateRange.end | localDateYYYYMMDD }}</span>
    </button>
    <transition name="slide-fade">
      <calendar
        v-show="isShowCalendar"
        :isShow="isShowCalendar"
        :selectedDateRange="selectedDateRange"
        :selectedPickDateStand="selectedPickDateStand"
        @close="toggleShowCalendar"
        @save="onSaveCalendar"
      />
    </transition>
  </div>
</template>
<script>
import Calendar from '@/component/calendar/Calendar';
import defaultDateRange from '@/const/defaultDateRange';

export default {
  name: 'CalendarPicker',
  props: [],
  data() {
    return {
      isShowCalendar: false,
      selectedPickDateStand: '지난 7일',
      selectedDateRange: { ...defaultDateRange },
    };
  },
  computed: {
    startDT() {
      return this.$options.filters.localDateYYYYMMDD(this.selectedDateRange.start);
    },
    endDT() {
      return this.$options.filters.localDateYYYYMMDD(this.selectedDateRange.end);
    },
  },
  created() {
    this.setSelectedDateRage();
  },
  mounted() {},
  watch: {
    $route() {
      this.setSelectedDateRage();
    },
  },
  methods: {
    setSelectedDateRage() {
      this.selectedDateRange.start = this.$route.query.startDT || defaultDateRange.start;
      this.selectedDateRange.end = this.$route.query.endDT || defaultDateRange.end;
    },
    toggleShowCalendar() {
      this.isShowCalendar = !this.isShowCalendar;
    },
    onSaveCalendar(dateRange, pickDateStand) {
      this.selectedDateRange = dateRange;
      this.selectedPickDateStand = pickDateStand;
      this.toggleShowCalendar();
      this.$router
        .push({
          query: {
            ...this.$route.query,
            startDT: this.startDT,
            endDT: this.endDT,
            page: 1,
          },
        })
        .catch((err) => err);
    },
  },
  components: { Calendar },
};
</script>
<style scoped lang="scss">
@import 'CalendarPicker';
</style>
