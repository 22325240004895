<template>
  <general-function-popup class="call-history-popup" :isNoneBottomButton="true">
    <div slot="title" class="title">
      <button class="hidden">
        <vue-material-icon name="keyboard_arrow_left" :size="30" />
      </button>
      <h2>영상 목록</h2>
      <button class="close-button" @click="onClosePopup">
        <vue-material-icon name="close" :size="24" />
      </button>
    </div>
    <div slot="contents" class="contents">
      <div class="call-history-info">
        <span class="call-id">
          Call ID
          <b>{{ callId }}</b>
        </span>
        <span v-if="isFactoryComp" class="download-count">
          남아있는 다운로드 수
          <b>{{ downloadLimit - currentDownloadCnt }}</b>
        </span>
      </div>
      <ul v-if="callHistoryContents.length > 0">
        <li v-for="(content, index) in callHistoryContents" :key="index">
          <div class="background-image" :style="{ backgroundImage: `url(${content['tumb']})` }" />
          <ul>
            <li>
              <span>업로더</span>
              <span class="uploader-wrapper">
                <span>{{ content['uploader']['companyName'] }}</span>
                <span>{{ content['uploader']['userName'] }}</span>
              </span>
            </li>
            <li>
              <span>재생 시간</span>
              <span>{{ content['playSeconds'] | formatSecondsToFullTime }}</span>
            </li>
            <li>
              <span>태그</span>
              <span v-if="content['tagList'].length > 0" class="label-wrapper">
                <span v-for="(tag, index) in content['tagList']" :key="`tag-${index}`" class="gray-label smaller">
                  {{ tag }}
                </span>
              </span>
              <span v-else>-</span>
            </li>
          </ul>
          <div class="button-wrapper" v-if="isFactoryComp">
            <button-basic color="red" size="s" :width="100" @click="onClickDeleteContent(content.id)">
              삭제
            </button-basic>
            <button-basic
              color="dark"
              size="s"
              :width="100"
              :loading-dot="downloadingFileContentId === content.id"
              @click="getDownloadCallHistoryContent(content)"
            >
              다운로드
            </button-basic>
          </div>
        </li>
      </ul>
      <p v-else class="list-data-none">영상 목록이 없습니다.</p>
      <confirm-popup
        :isShow="showedPopupName === 'ConfirmPopup'"
        :contentsKey="popupContentsKey"
        @confirm="onConfirmInPopup"
        @close="togglePopup"
      />
    </div>
  </general-function-popup>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import GeneralFunctionPopup from '@/component/popup/generalFunctionPopup/GeneralFunctionPopup';
import ConfirmPopup from '@/component/popup/confirmPopup/ConfirmPopup';
import downloadFile from '@/asset/js/downloadFile';
import moment from 'moment';

export default {
  name: 'CallHistoryPopup',
  props: ['callId'],
  data() {
    return {
      showedPopupName: '',
      popupContentsKey: '',
      downloadLimit: 0,
      currentDownloadCnt: 0,
      callHistoryContents: [],
      deletingContentId: null,
      downloadingFileContentId: null,
    };
  },
  computed: {
    ...mapGetters('users', ['isFactoryComp']),
  },
  created() {
    this.getCallHistoryContents();
  },
  mounted() {},
  watch: {},
  methods: {
    ...mapActions('callHistories', [
      'GET_CALL_HISTORY_CONTENTS',
      'GET_DOWNLOAD_CALL_HISTORY_CONTENT',
      'DELETE__CALL_HISTORY_CONTENT',
    ]),
    onConfirmInPopup() {
      switch (this.popupContentsKey) {
        case 'DELETE_REMOTE_VIDEO':
          this.deleteCallHistoryContent(this.deletingContentId);
          this.deletingContentId = null;
          this.togglePopup();
          break;
        default:
          break;
      }
    },
    togglePopup(popupName, popupContentsKey) {
      this.showedPopupName = popupName || '';
      this.popupContentsKey = popupContentsKey || '';
    },
    onClosePopup() {
      this.$emit('close', this.callHistoryContents);
    },
    onClickDeleteContent(contentId) {
      this.deletingContentId = contentId;
      this.togglePopup('ConfirmPopup', 'DELETE_REMOTE_VIDEO');
    },
    async getCallHistoryContents() {
      const data = await this.GET_CALL_HISTORY_CONTENTS(this.callId);
      this.downloadLimit = data['downloadLimit'];
      this.currentDownloadCnt = data['currentDownloadCnt'];
      this.callHistoryContents = data['contentList'];
    },
    async getDownloadCallHistoryContent(content) {
      try {
        this.downloadingFileContentId = content.id;
        const response = await this.GET_DOWNLOAD_CALL_HISTORY_CONTENT([this.callId, content.id]);
        downloadFile(
          response,
          `msfremote_${this.callId}_${moment.utc(content['createDT']).local().format('YYYYMMDDHHmm')}.mp4`,
        );
        this.currentDownloadCnt++;
      } finally {
        this.downloadingFileContentId = null;
      }
    },
    async deleteCallHistoryContent(contentId) {
      await this.DELETE__CALL_HISTORY_CONTENT(contentId);
      await this.getCallHistoryContents();
    },
  },
  components: { GeneralFunctionPopup, ConfirmPopup },
};
</script>
<style scoped lang="scss">
@import 'CallHistoryPopup';
</style>
