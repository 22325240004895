var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "calendar-picker" },
    [
      _c(
        "button",
        {
          class: { focused: _vm.isShowCalendar },
          on: { click: _vm.toggleShowCalendar },
        },
        [
          _c("vue-material-icon", {
            attrs: { name: "event_available", size: 14 },
          }),
          _c("span", [
            _vm._v(
              _vm._s(_vm._f("localDateYYYYMMDD")(_vm.selectedDateRange.start)) +
                " ~ " +
                _vm._s(_vm._f("localDateYYYYMMDD")(_vm.selectedDateRange.end))
            ),
          ]),
        ],
        1
      ),
      _c(
        "transition",
        { attrs: { name: "slide-fade" } },
        [
          _c("calendar", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.isShowCalendar,
                expression: "isShowCalendar",
              },
            ],
            attrs: {
              isShow: _vm.isShowCalendar,
              selectedDateRange: _vm.selectedDateRange,
              selectedPickDateStand: _vm.selectedPickDateStand,
            },
            on: { close: _vm.toggleShowCalendar, save: _vm.onSaveCalendar },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }